import { CreateUserDTO } from '@/core/admin/DTO/UserToCreateDTO';
import { Languages } from '@/core/admin/constants/Languages.const';
import { USER_PERMISSION } from '@/core/user/enums/UserPermission';

export class UpdateUserDTO {
  id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  isActive?: boolean;
  permissions?: USER_PERMISSION[];
  role?: string;
  language?: Languages;

  constructor(id: string, payload: Partial<CreateUserDTO>) {
    this.id = id;
    if (payload.lastName) {
      this.lastName = payload.lastName;
    }
    if (payload.email) {
      this.email = payload.email;
    }
    if (payload.firstName) {
      this.firstName = payload.firstName;
    }
    if (payload.permissions) {
      this.permissions = payload.permissions;
    }
    if (payload.isActive || payload.isActive === false) {
      this.isActive = payload.isActive;
    }
    if (payload.role) {
      this.role = payload.role;
    }
    if (payload.language) {
      this.language = payload.language;
    }
  }
}
