import { USER_ROLE } from '@/core/user/enums/UserRole';

export function getTranslationIdentifierFromUserRole(userRole: USER_ROLE): string {
  switch (userRole) {
    case USER_ROLE.ADMIN:
      return 'Admin.general.user-role-admin';
    case USER_ROLE.EDITOR:
      return 'Admin.general.user-role-editor';
    case USER_ROLE.SUPER_ADMIN:
      return 'Admin.general.user-role-super-admin';
    case USER_ROLE.USER:
      return 'Admin.general.user-role-user';
  }
}
