












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'DialogTitle',
})
export default class DialogTitle extends Vue {
  @Prop({ type: String, required: true }) title!: string;

  closeDialog() {
    this.$emit('close');
  }
}
