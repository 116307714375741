











































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { isEmail, isNotEmpty } from '@/ui/services/admin/InputVerification';
import { UserToCreateDTO } from '@/core/admin/DTO/UserToCreateDTO';
import { getTranslationIdentifierFromLanguages, Languages } from '@/core/admin/constants/Languages.const';
import { USER_ROLE } from '@/core/user/enums/UserRole';
import { getTranslationIdentifierFromUserRole } from '@/core/admin/constants/UserRoles.const';
import type { User } from '@/core/admin/models/User';
import getLogger from '@/shared/logger/logger';
import { USER_PERMISSION } from '@/core/user/enums/UserPermission';
import { UpdateUserDTO } from '@/core/admin/DTO/UpdateUserDto';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = getLogger('UserForm');

interface SelectItemDescription {
  text: string;
  value: Languages | USER_ROLE;
}

interface VForm extends HTMLFormElement {
  validate(): boolean;
  reset(): boolean;
}

@Component({
  name: 'UserForm',
})
export default class NewUserForm extends Vue {
  @Prop({ default: false }) saveNew: boolean | undefined;
  @Prop({ default: false }) saveUpdate: boolean | undefined;
  @Prop({ default: undefined }) user: User | undefined;
  email = '';
  firstName = '';
  language = Languages.DE;
  lastName = '';
  hasStatistics = false;
  canDownload = false;
  isActive = false;
  role = '';

  languagesToSelect: Array<SelectItemDescription> = [
    { value: Languages.DE, text: this.$t(getTranslationIdentifierFromLanguages(Languages.DE)) },
    { value: Languages.FR, text: this.$t(getTranslationIdentifierFromLanguages(Languages.FR)) },
    { value: Languages.IT, text: this.$t(getTranslationIdentifierFromLanguages(Languages.IT)) },
    { value: Languages.EN, text: this.$t(getTranslationIdentifierFromLanguages(Languages.EN)) },
  ];

  userGroupsToSelect: Array<SelectItemDescription> = [
    { value: USER_ROLE.ADMIN, text: this.$t(getTranslationIdentifierFromUserRole(USER_ROLE.ADMIN)) },
    {
      value: USER_ROLE.SUPER_ADMIN,
      text: this.$t(getTranslationIdentifierFromUserRole(USER_ROLE.SUPER_ADMIN)),
    },
    { value: USER_ROLE.USER, text: this.$t(getTranslationIdentifierFromUserRole(USER_ROLE.USER)) },
    {
      value: USER_ROLE.EDITOR,
      text: this.$t(getTranslationIdentifierFromUserRole(USER_ROLE.EDITOR)),
    },
  ];

  mounted(): void {
    if (this.user) {
      this.setInputValues(this.user);
    }
  }

  @Watch('user')
  onStartingUserChange() {
    if (this.user) {
      this.setInputValues(this.user);
    }
  }

  @Watch('saveNew')
  onSave(newValue: boolean) {
    if (this.isAllInputValid() && newValue) {
      const userToCreate = new UserToCreateDTO(
        this.lastName,
        this.firstName,
        this.email,
        this.role,
        this.isActive,
        this.getPermissions(),
        this.language,
      );
      this.$emit('createUserValidInput', userToCreate);
    } else {
      this.$emit('inputIsInvalid');
    }
  }

  @Watch('saveUpdate')
  onUpdate(newValue: boolean) {
    if (this.user) {
      if (this.isAllInputValid() && newValue) {
        const userToUpdate = new UpdateUserDTO(
          this.user.id,
          new UserToCreateDTO(
            this.lastName,
            this.firstName,
            this.email,
            this.role,
            this.isActive,
            this.getPermissions(),
            this.language,
          ),
        );
        this.$emit('updateUserValidInput', userToUpdate);
      } else {
        this.$emit('inputIsInvalid');
      }
    }
  }

  get isEmail() {
    return isEmail;
  }

  get isNotEmpty() {
    return isNotEmpty;
  }

  getPermissions(): USER_PERMISSION[] {
    return [
      this.hasStatistics ? USER_PERMISSION.STATISTICS : undefined,
      this.canDownload ? USER_PERMISSION.DOWNLOAD : undefined,
    ].filter(Boolean) as USER_PERMISSION[];
  }

  isAllInputValid() {
    const ref = this.$refs.form as VForm;
    return ref.validate();
  }

  clearInput() {
    this.email = '';
    this.firstName = '';
    this.language = Languages.DE;
    this.lastName = '';
    this.hasStatistics = false;
    this.canDownload = false;
    this.isActive = false;
    this.role = '';
    if (this.user) {
      this.setInputValues(this.user);
    }
  }

  setInputValues(user: User): void {
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.email = user.email;
    this.role = user.role;
    this.isActive = user.isActive;
    this.hasStatistics = user.permissions.includes(USER_PERMISSION.STATISTICS);
    this.canDownload = user.permissions.includes(USER_PERMISSION.DOWNLOAD);
    this.language = user.language;
  }
}
