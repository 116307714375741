














































import { Vue, Component, Prop } from 'vue-property-decorator';
import { CreateUserDTO } from '@/core/admin/DTO/UserToCreateDTO';
import UserForm from '@/ui/components/organisms/forms/UserForm.vue';
import type { User } from '@/core/admin/models/User';
import { createUserUseCase } from '@/core/admin/usecases/createUserUseCase';
import DialogTitle from '@/ui/components/atoms/textItems/DialogTitle.vue';
import { AlertStoreActions, AlertType } from '@/ui/stores/AlertStore';
import { AdminStoreMutations } from '@/ui/stores/AdminStore';
import { updateUserUseCase } from '@/core/admin/usecases/updateUserUseCase';
import { UpdateUserDTO } from '@/core/admin/DTO/UpdateUserDto';
import ButtonWithText from '@/ui/components/atoms/buttons/ButtonWithText.vue';

@Component({
  name: 'UserDialog',
  components: { ButtonWithText, DialogTitle, UserForm },
})
export default class NewUserDialog extends Vue {
  @Prop() userId!: string;
  @Prop({ default: null }) user: User | undefined;
  @Prop({ type: Boolean }) showDialog!: boolean;
  @Prop({ type: Boolean }) isNewUser!: boolean;
  @Prop({ type: Boolean }) isEditUser!: boolean;
  private triggerSaveNewOnForm = false;
  private triggerSaveUpdateOnForm = false;
  private isLoading = false;

  get currentUser(): User {
    return this.$store.state.userStore.currentUser;
  }

  async createUserValidInput(userToCreate: CreateUserDTO) {
    this.isLoading = true;
    createUserUseCase(this.currentUser, userToCreate)
      .then(() => {
        this.closeDialog();
        this.$store.commit(AdminStoreMutations.SET_IS_NEW_USER_CREATED, true);
        this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
          message: 'Admin.create-user.success-message',
          type: AlertType.SUCCESS,
        });
        this.$emit('created');
      })
      .catch(() => {
        this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
          message: 'Admin.create-user.error-message',
          type: AlertType.ERROR,
        });
      })
      .finally(() => {
        this.triggerSaveNewOnForm = false;
        this.isLoading = false;
      });
  }

  async updateUserValidInput(userToUpdate: UpdateUserDTO) {
    this.isLoading = true;
    updateUserUseCase(this.currentUser, userToUpdate)
      .then(() => {
        this.closeDialog();
        this.$store.commit(AdminStoreMutations.SET_IS_NEW_USER_CREATED, true);
        this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
          message: 'Admin.edit-user.success-alert',
          type: AlertType.SUCCESS,
        });
      })
      .catch(() => {
        this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
          message: 'Admin.edit-user.error-alert',
          type: AlertType.ERROR,
        });
      })
      .finally(() => {
        this.triggerSaveUpdateOnForm = false;
        this.isLoading = false;
      });
  }

  handleInvalidInput() {
    this.triggerSaveNewOnForm = false;
    this.triggerSaveUpdateOnForm = false;
  }

  closeDialog() {
    this.$emit('close');
    this.clearInput();
  }

  clearInput() {
    const ref = this.$refs.userForm as UserForm;
    ref.clearInput();
  }

  createUser() {
    this.triggerSaveNewOnForm = true;
  }

  updateUser() {
    this.triggerSaveUpdateOnForm = true;
  }
}
