import { container } from 'tsyringe';
import type { User } from '@/core/admin/models/User';
import { AdminService } from '@/core/admin/services/AdminServices';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import { CreateUserDTO } from '../DTO/UserToCreateDTO';

export const createUserUseCase = async (currentUser: CurrentUser, user: CreateUserDTO): Promise<User> => {
  const adminService = container.resolve<AdminService>('AdminService');

  return await adminService.createUser(currentUser, user);
};
