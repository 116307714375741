import { Languages } from '@/core/admin/constants/Languages.const';
import { USER_PERMISSION } from '@/core/user/enums/UserPermission';

export interface CreateUserDTO {
  lastName: string;
  firstName: string;
  email: string;
  role: string;
  isActive: boolean;
  permissions: USER_PERMISSION[];
  language: Languages;
}

export class UserToCreateDTO implements CreateUserDTO {
  email: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  permissions: USER_PERMISSION[];
  language: Languages;
  role: string;

  constructor(
    lastName: string,
    firstName: string,
    email: string,
    role: string,
    isActive: boolean,
    permissions: USER_PERMISSION[],
    language: Languages,
  ) {
    this.lastName = lastName;
    this.firstName = firstName;
    this.email = email;
    this.permissions = permissions;
    this.isActive = isActive;
    this.role = role;
    this.language = language;
  }
}
